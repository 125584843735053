<template>
    <div>
    <stock-form
      :is-stock-form-sidebar-active.sync="isStockFormSidebarActive"
      :headertext="HeaderText"
      :stock-data="StockData"
      :groupchoice="group_choice"
      @refetch-data="updatedData" 
    />
        <b-card no-body>
            <div class="m-2 bg-viva">
                <b-row class="justify-content-end">
                    <b-col class="pt-1 pb-1" cols="2">
                        <div>
                        <b-button 
                            @click="addCourseMeterial"
                            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                            variant="flat-dark">
                            <feather-icon icon="PlusIcon" />
                            เพิ่มวัสดุ
                        </b-button>
                        </div>
                    </b-col>
                    <b-col class="pt-1 pb-1" cols="2">
                        <div>
                        <b-button 
                            @click="addCourseService"
                            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                            variant="flat-warning">
                            <feather-icon icon="PlusIcon" />
                            เพิ่มบริการ
                        </b-button>
                        </div>
                    </b-col>
                    <b-col class="pt-1 pb-1" cols="2">
                        <div>
                        <b-button 
                            @click="addCourseMedicine"
                            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                            variant="flat-danger">
                            <feather-icon icon="PlusIcon" />
                            เพิ่มยา
                        </b-button>
                        </div>
                    </b-col>
                    <b-col class="pt-1 pb-1" cols="2">
                        <div>
                        <b-button
                            @click="addCourseEquipment"
                            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                            variant="flat-primary">
                            <feather-icon icon="PlusIcon" />
                            เพิ่มอุปกรณ์
                        </b-button>
                        </div>
                    </b-col>
                </b-row>
                <div class="m-2">
                <b-row>
                    <b-col lg="4" class="pt-1 pb-1">
                        <span class="c-gray-text font-weight-bold">ชื่อ:</span>
                        <span class="c-gray-text font-weight-bold">{{Data.title}}</span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="4" class="pt-1 pb-1">
                        <span class="c-gray-text font-weight-bold">หมวดหมู่:</span>
                        <span class="c-gray-text font-weight-bold">{{Data.category_name}}</span>
                    </b-col>
                    <b-col lg="4" class="pt-1 pb-1">
                        <span class="c-gray-text font-weight-bold">ประเภท:</span>
                        <span class="c-gray-text font-weight-bold">{{Data.type_name}}</span>
                    </b-col>
                    <b-col lg="4" class="pt-1 pb-1">
                        <span class="c-gray-text font-weight-bold">สถานะ:</span>
                        <b-badge
                        pill
                        :variant="`light-${resolveUserStatusVariant(Data.is_active)}`"
                        class="text-capitalize"
                        >
                            {{ resolveUserStatusnameVariant(Data.is_active) }} 
                        </b-badge>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="4" class="pt-1 pb-1">
                        <span class="c-gray-text font-weight-bold">ค่าบริการ:</span>
                        <span class="c-gray-text font-weight-bold">{{Data.price}}</span>
                    </b-col>
                    <b-col lg="4" class="pt-1 pb-1">
                        <span class="c-gray-text font-weight-bold">ค่าแพทย์:</span>
                        <span class="c-gray-text font-weight-bold">{{Data.wage}}</span>
                    </b-col>
                    <b-col lg="4" class="pt-1 pb-1">
                        <span class="c-gray-text font-weight-bold">ค่ามือพนักงาน:</span>
                        <span class="c-gray-text font-weight-bold">{{Data.commission}}</span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="4" class="pt-1 pb-1">
                        <span class="c-gray-text font-weight-bold">สร้างเมื่อ:</span>
                        <span class="c-gray-text font-weight-bold">{{Data.created_at}}</span>
                    </b-col>
                    <b-col lg="4" class="pt-1 pb-1">
                        <span class="c-gray-text font-weight-bold">แก้ไขล่าสุด:</span>
                        <span class="c-gray-text font-weight-bold">{{Data.updated_at}}</span>
                    </b-col>
                </b-row>
                </div>
            </div>
        </b-card>
    </div>
</template>
<script>
import { BBreadcrumb } from 'bootstrap-vue'
 import {
   BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BFormCheckbox, BFormInvalidFeedback, BButton,BTabs, BTab, BFormSelect
 } from 'bootstrap-vue'
 //upload
 import {
  BCard,BCardText,BMedia,BMediaAside,BMediaBody,BAvatar,BImg,BLink,BFormFile,BRow,BCol,BBadge
 } from 'bootstrap-vue'
import StockForm from './StockForm.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref,onUnmounted } from '@vue/composition-api'
import store from '@/store'
import courseStoreModule from '../courseStoreModule'
import useCourseStockList from './useCourseStockList'

 import Ripple from 'vue-ripple-directive'
 import router from '@/router'

 export default {
    directives: {
        Ripple,
    },
    components:{
        BBreadcrumb,
        BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BFormCheckbox, BFormInvalidFeedback, BButton,BTabs, BTab,
        BCard,BCardText,BMedia,BMediaAside,BMediaBody,BAvatar,BImg,BLink,BFormFile,BRow,BCol,BFormSelect,BBadge,
        ValidationProvider,
        ValidationObserver,
        StockForm
    },
    model: {
      prop: 'isStockFormSidebarActive',
      event: 'update:is-stock-form-sidebar-active',
    },
    setup(){
        const Data =ref({});
        const HeaderText = ref('')
        const isStockFormSidebarActive = ref(false)
        const StockData = ref({})
        const id = ref(router.currentRoute.params.id);
        const group_choice = ref(0)
        const userStorage = JSON.parse(localStorage.getItem('userData'));

        const PATIENTS_STORE_MODULE_NAME = 'app-course';
        if (!store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.registerModule(PATIENTS_STORE_MODULE_NAME, courseStoreModule)
        onUnmounted(() => {
            if (store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.unregisterModule(PATIENTS_STORE_MODULE_NAME)
        });

        store.dispatch('app-course/fetchData', {id:id.value})
           .then((res) => {
                console.log(res.data.data[0]);
                Data.value = res.data.data[0];
            });

        const resolveUserStatusVariant = status => {
       
        if (status) return 'success'
        if (!status) return 'secondary'
        return 'primary'
        }
        const resolveUserStatusnameVariant = status => {
        
            if (status) return 'Active'
            if (!status) return 'Inactive'
            return 'primary'
        }
        const resolveCourseVariant = status =>{
        if (status == 'วัสดุ') return 'dark'
        else if (status == 'บริการ') return 'warning'
        return 'light'
        }
        const resolveCourseGroupnameVariant = status =>{
        if (status == 'วัสดุ') return 'ShieldIcon'
        else if (status == 'บริการ') return 'SmileIcon'
        return 'light'
        }

        const addCourseMeterial = (data)=>{
        
        StockData.value = {};
        HeaderText.value = 'เพิ่มวัสดุ';
        group_choice.value = 3;
        isStockFormSidebarActive.value = true;
        }
        const addCourseService = (data)=>{
        
        StockData.value = {};
        HeaderText.value = 'เพิ่มบริการ';
        group_choice.value = 4;
        isStockFormSidebarActive.value = true;
        }
        
        const addCourseMedicine = (data)=>{
        
        StockData.value = {};
        HeaderText.value = 'เพิ่มยา';
        group_choice.value = 1;
        isStockFormSidebarActive.value = true;
        }
        
        const addCourseEquipment = (data)=>{

        StockData.value = {};
        HeaderText.value = 'เพิ่มอุปกรณ์';
        group_choice.value = 2;
        isStockFormSidebarActive.value = true;
        }

        const {
            fetchData,
        } = useCourseStockList(id);

        function updatedData () {
        this.$emit('refetch-data')
        };

        return {
            updatedData,
            group_choice,
            fetchData,
            Data,
            addCourseMeterial,
            addCourseService,
            addCourseMedicine,
            addCourseEquipment,
            StockData,
            HeaderText,
            isStockFormSidebarActive,
            resolveUserStatusVariant,
            resolveUserStatusnameVariant,
            resolveCourseVariant,
            resolveCourseGroupnameVariant

        }
        
    }
 }
</script>
<style>
.c-blue-header{
    color:#04497C !important;
}
.c-gray-text{
    color:#808080 !important;
}
.breakline-top{
    border-top:1px solid #D9D9D9 !important;
}
.breakline-bottom{
    border-bottom:1px solid #D9D9D9 !important;
}
</style>
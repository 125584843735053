import axios from '@axios'
export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post('/course/v1/getall',data)
                .then(response => resolve(response))
                .catch(error => {reject(error)})
            })
        },
        typesData(ctx,queryParams){
           
              
            return new Promise((resolve, reject) => {
              axios
                .post('/course/v1/gettypes')
                .then(response => resolve(response))
                .catch(error => {reject(error)})
            })
        },
        async addData(ctx,queryParams){
            var data = queryParams;

            return new Promise((resolve, reject) => {
                axios
                  .post('/course/v1/add',data)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        async updateData(ctx,queryParams){
            var data = queryParams;

            return new Promise((resolve, reject) => {
                axios
                  .put(`/course/v1/update/${data.id}`,data)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        async deleteData(ctx,queryParams){
 
            return new Promise((resolve, reject) => {
                axios
                  .delete('/course/v1/delete/'+queryParams.id)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        fetchCategoryData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post('/course/category/v1/getall',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },

        //manage course_medicine
        fetchStockData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post('/course/medicines/v1/getall',data)
                .then(response => resolve(response))
                .catch(error => {reject(error)})
            })
        },
        async addStockData(ctx,queryParams){
            var data = queryParams;

            return new Promise((resolve, reject) => {
                axios
                  .post('/course/medicines/v1/add',data)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        async deleteStockData(ctx,queryParams){
            var data = queryParams;

            return new Promise((resolve, reject) => {
                axios
                  .delete('/course/medicines/v1/delete/'+queryParams.id)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        async updateStockData(ctx,queryParams){
            var data = queryParams;

            return new Promise((resolve, reject) => {
                axios
                  .put(`/course/medicines/v1/update/${data.id}`,data)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        fetchMedicineData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post('/medicines/v1/getall',data)
                .then(response => resolve(response))
                .catch(error => {reject(error)})
            })
        },
        fetchMeterialData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post('/meterial/v1/getall',data)
                .then(response => resolve(response))
                .catch(error => {reject(error)})
            })
        },
        //course_set
        fetchCourseSetData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post('/course/set/v1/getall',data)
                .then(response => resolve(response))
                .catch(error => {reject(error)})
            })
        },
        addCoursesPatient(ctx,queryParams){
            var data = JSON.stringify(queryParams);
            return new Promise((resolve, reject) => {
              axios
                .post('/patients/v1/addCoureses',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
           
          },
        async addCourseSetData(ctx,queryParams){
            var data = queryParams;

            return new Promise((resolve, reject) => {
                axios
                  .post('/course/set/v1/add',data)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        async deleteCourseSetData(ctx,queryParams){
            var data = queryParams;

            return new Promise((resolve, reject) => {
                axios
                  .delete('/course/set/v1/delete/'+queryParams.id)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        async updateCourseSetData(ctx,queryParams){
            var data = queryParams;

            return new Promise((resolve, reject) => {
                axios
                  .put(`/course/set/v1/update/${data.id}`,data)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        //course_set_item
        fetchCourseSetItemData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post('/course/set/item/v1/getall',data)
                .then(response => resolve(response))
                .catch(error => {reject(error)})
            })
        },
        async addCourseSetItemData(ctx,queryParams){
            var data = queryParams;

            return new Promise((resolve, reject) => {
                axios
                  .post('/course/set/item/v1/add',data)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        async deleteCourseSetItemData(ctx,queryParams){
            var data = queryParams;

            return new Promise((resolve, reject) => {
                axios
                  .delete('/course/set/item/v1/delete/'+queryParams.id)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        async deleteCourseSetItemAllData(ctx,queryParams){
            var data = queryParams;

            return new Promise((resolve, reject) => {
                axios
                  .delete('/course/set/item/v1/delete/all/'+queryParams.set_id)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        async updateCourseSetItemData(ctx,queryParams){
            var data = queryParams;

            return new Promise((resolve, reject) => {
                axios
                  .put(`/course/set/item/v1/update/${data.id}`,data)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
    }
}